
@media all and (min-width: 992px) {
    .amount-select-image {
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }

    .amount-select-image-change {
        width: 30px;
        height: 30px;
        margin-left: 70px;
    }

    .amount-select-to-image-change {
        width: 30px;
        height: 30px;
        margin-left: 190px;
    }
}


@media (max-width: 991px) {
    .amount-select-image {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }

    .amount-select-image-change {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }


    .amount-select-to-image-change {
        width: 24px;
        height: 24px;
        margin-left: 10px;
    }
}